import Highway from "@dogstudio/highway";
import {TweenMax, Power4, TimelineMax} from "gsap";

class SlideIn extends Highway.Transition {
    out({from, done}) {
        
        // var scrollOptions = {
        //     top: 0,
        //     behavior:'auto'
        // }
    
        // window.scrollTo(scrollOptions);

        // Animation disappear
        // TweenMax.fromTo(from, 0.1, {
        //     autoAlpha: 1
        // }, {
        //     autoAlpha: 0,
        //     ease: Power4.easeOut,
        //     onComplete: done
        // });

        const tl = new TimelineMax({
            onComplete: done
        })
        
        // Animation
        tl.set(from, {
            x: 0,
            scale: 0.8,
            transformOrigin: pageSize(),
        })
        
        tl.from(from, 0.3, {
            scale: 1,
            ease: Power4.easeOut
        })
        
        tl.from(from, 0.3, {
            x: -1,
            ease: Power4.easeOut
        });
    }
    in({from, to, done}) {

        // Remove Old View
        from.remove();
        
        const tl = new TimelineMax({
            onComplete: done
        })
        
        // Animation
        tl.set(to, {
            x: window.innerWidth * 1.5,
            scale: 0.8,            
            transformOrigin: pageSize()
        })
        
        tl.to(to, 0.3, {
            x: 0,
            ease: Power4.easeOut
        });
        
        tl.to(to, 1, {
            scale: 1,
            ease: Power4.easeOut,
        });
    }
}

function pageSize() {
    const page = document.querySelector('main');
    const pageClass = page.classList;

    if( pageClass.contains('home') ||  pageClass.contains('contact') ||  pageClass.contains('thankYou')) {
        return '50% 50%'
    } else  {
        return '50% 0%'
    }

}

export default SlideIn;