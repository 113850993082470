import {TweenMax} from "gsap";
import * as charming from 'charming';
import Highway from "@dogstudio/highway";
import SlideIn from './pageTransition';

//Button To Top//
//Get the button
var toTopButton = document.getElementById("toTop");
// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function() {scrollFunction()};
function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      toTopButton.style.display = "block";
  } else {
      toTopButton.style.display = "none";
  }
}
// When the user clicks on the button, scroll to the top of the document
toTopButton.onclick = function() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}


// Navigation slide in/out animation //
function navAnimation(x) {
    $('.modal .modal-dialog').attr('class', 'modal-dialog modal-full element-animation ' + x);
};
$('#modalNavigation').on('show.bs.modal', function (e) {
    var anim = 'navslidein';
    navAnimation(anim);
})
$('#modalNavigation').on('hide.bs.modal', function (e) {
    var anim = 'navslideout';
    navAnimation(anim);
})


// active navigation
function activeNav() {
    const currentPage = document.querySelector('#nav a[href$="/' + location.pathname.split("/")[1] + '"]');
    const activeNavLink = document.querySelector('.nav-link.active');
    
    if ((location.pathname.split("/")[1]) !== "") {
        if (activeNavLink) {
            activeNavLink.classList.remove('active');
        }
        currentPage.classList.add('active');
    }
    else {
        if (activeNavLink) {
            activeNavLink.classList.remove('active');
        }
        document.querySelector('#nav li.home-link a').classList.add('active');
    }
}


// Page runner //
pageRunner();

function pageRunner() {
    const page = document.querySelector('main');
    const pageClass = page.classList;

    if( pageClass.contains('home') ) {
        homeAnimation();
        clientSlider();
    } else if ( pageClass.contains('career') ) {
        careerPage();
    } else if ( pageClass.contains('works') ) {
        workPage();
        clientSlider();
    } else if ( pageClass.contains('get-in-touch') || pageClass.contains('about') || pageClass.contains('service') || pageClass.contains('works') ) {
        contactForm(); // run Contact Form
    } else if ( pageClass.contains('biteCraft') ) {
        biteCraftPage();
    }
}


// Call Highway.Core once for page transition //
const H = new Highway.Core({
    transitions: {
      default: SlideIn
    }
});
H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {
    // Reset Scroll
    window.scrollTo(0, 0);
});
H.on('NAVIGATE_END', ({ to, from, trigger, location }) => {
    activeNav();
    pageRunner();

    if (location.anchor) {
        // Get element
        const el = document.querySelector(location.anchor);
    
        if (el) {
          // Scroll to element
          window.scrollTo(el.offsetLeft, el.offsetTop);
        }
    }
});


//Initiate Slider Animation //
new WOW().init();

function careerPage() {
    // active class on selected position
    $(".career-card").on("show.bs.collapse hide.bs.collapse", function(e) {
        if (e.type=='show'){
          $(this).addClass('active');
        }else{
          $(this).removeClass('active');
        }
    });

    // upload cv show file name
    $('#customFileUpload').change(function() {
        $('#customFileName').text(this.files[0].name);
    });

    // validate cv on submission
    function validateOnSubmit(FrmJoinUs) {
        if(FrmJoinUs.cv.value=='')
        {
            alert("Please upload your CV.");
            return false;
        }

        /*
        trackSubmit();
        ga('send', 'event', { eventCategory: 'FOREFRONT Job Application', eventAction: 'Submit Register', eventLabel: '10 Reasons'});
        */
        return true;
    }

    $("#joinus").submit(function(event) {
        console.log('form submitted.');

        if (!grecaptcha.getResponse()) {
            console.log('captcha not yet completed.');

            event.preventDefault(); //prevent form submit
            grecaptcha.execute();
        } else {
            fbq('track', 'CompleteRegistration');
            console.log('form really submitted.');
        }
    });
	
	function submitForm(token) {
		$("#joinus").submit();
		/*if (validate_form(form)) {
			form.submit();
		} else {
			grecaptcha.reset();
		}*/
	}

    formErrorMessage();
}

function workPage() {
    
}

function clientSlider() {
    // Work - Client Slider
    $('.client-slider').slick({
        lazyLoad: 'ondemand',
        slidesToShow: 7,
        infinite: true,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 1500,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    });
}

function biteCraftPage() {
    // Bite Craft Videos Slider
    $('.craft-videos-slider').slick({
        lazyLoad: 'ondemand',
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '20px',
        infinite: true,
        variableWidth: true,
        draggable: true,
        speed: 1000
    });
}


function contactForm() {
    formErrorMessage();

    $("#contactus").submit(function(event) {
        console.log('form submitted.');

        // if (!grecaptcha.getResponse()) {
        // 	console.log('captcha not yet completed.');

        // 	event.preventDefault(); //prevent form submit
        // 	grecaptcha.execute();
        // } else {
        // fbq('track', 'CompleteRegistration');
        // 	console.log('form really submitted.');
        // }
    });

    function submitForm(token) {
        $("#contactus").submit();
        /*if (validate_form(form)) {
            form.submit();
        } else {
            grecaptcha.reset();
        }*/
    }

    
    function validateForm(f)
    {
        if(f.fullname.value=='')
        {
            alert('Please enter your full name.');
            f.fullname.focus();
            return false;
        }

        if(f.email.value=='')
        {
            alert('Please enter your email.');
            f.email.focus();
            return false;
        }

        var regex_email = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if(!regex_email.test(f.email.value))
        {
            alert('Please insert a valid Email.');
            f.email.focus();
            return false;
        }

        if(f.message.value=='')
        {
            alert('Please enter your message.');
            f.message.focus();
            return false;
        }
        
        return( true );
    }
}


// Home - changing text
function homeAnimation() {
    var textChange = ["effective<span class='d-block'></span>ideas", 
                        "bold<span class='d-block'></span>executions", 
                        "meaningful<span class='d-block'></span>insights"];
    var counter = 0;
    var elem = $("#homeChangeText"); // Get Wrap el
    const homeTxt = document.querySelector('#homeChangeText'); // Get Wrap el
    charming(homeTxt); // Separate letter
    const homeTxtSpan = homeTxt.querySelectorAll('span'); // Get all child letter el
    TweenMax.fromTo(elem, 0.1, {autoAlpha: 0}, {autoAlpha: 1}); // Animate Wrap el
    TweenMax.staggerFromTo(homeTxtSpan, 0.3, {autoAlpha: 0, y: 20}, {autoAlpha: 1, y: 0}, 0.03 ); // Animate in all child el
    TweenMax.staggerTo(homeTxtSpan, 0.3, {autoAlpha: 0, y: -40, delay:2}, 0.03 ); // Animate out child el

    setInterval(change, 3000);
    function change() {
        TweenMax.fromTo(elem, 0.1, {autoAlpha: 0}, {autoAlpha: 1, onComplete: animateInText}); // Animate Wrap el in
    
        elem.html(textChange[counter]);
        charming(homeTxt);
        const homeTxtSpan2 = homeTxt.querySelectorAll('span'); // Get child letter once again
        counter++;
        if(counter >= textChange.length) { 
            counter = 0;
        }
        function animateInText() {
            TweenMax.staggerFromTo(homeTxtSpan2, 0.3, {autoAlpha: 0, y: 20}, {autoAlpha: 1, y: 0}, 0.03 ); // Animate in child letter
            TweenMax.staggerTo(homeTxtSpan2, 0.3, {autoAlpha: 0, y: -40, delay:2}, 0.03 ); // Animate out child letter
        }
    }
}

function formErrorMessage() {
    // show hidden div message if there's error in sending form
    if (window.location.href.indexOf("error") != -1) {
        $("#error").css("display", "block");
    }
}
